import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { OneTimeOrderStep1 } from './OneTimeOrderStep1';
import { OneTimeOrderStep2 } from './OneTimeOrderStep2';
import { axios } from '../../api';
import { Close } from '../../assets/Close';
import { formatingOptions } from '../../formatingOptions';
import { useGetProducts } from '../../hooks/getProducts';
import { generateFullName } from '../../nameUtils';
import { validateOneTimeOrderProducts } from '../../orderUtils';
import { Option } from '../../pages/types';
import { BOTTOM_TOAST, generateErrorMsg, MULTIPLE_MEAL_ONE_TIME_PRODUCTS } from '../../toastUtils';
import { LineInfo } from '../LineInfo';

export const OneTimeOrderContext = React.createContext<any>(null);

export const OneTimeOrderModal = ({ customer, onConfirm, onCancel }: any) => {
  const { pets, addresses } = customer;

  const [selectedDog, setSelectedDog] = useState<Option>();
  const [selectedProducts, setSelectedProducts] = useState<Option[]>([]);
  const [shippingAddress, setShippingAddress] = useState<string>();

  const [paymentMethod, setPaymentMethod] = useState<string>('--');
  const { products, isLoading } = useGetProducts();

  const [currentStep, setCurrentStep] = useState(1);

  const dogs = formatingOptions(pets, { value: 'id', label: 'name' });

  const getPaymentMethod = () => {
    axios
      .get(`payment_methods/?account_id=${customer.id}`)
      .then((response) => {
        const defaultPaymentMethod = response.data.find((card: any) => card.is_default === true);
        if (defaultPaymentMethod?.card) {
          setPaymentMethod(
            `${defaultPaymentMethod.card.brand} x${defaultPaymentMethod.card.last4}`
          );
        }
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getPaymentMethod();
    // TODO: Come up with a common Address Formatter
    const { address1, address2, city, state, zip, country } = addresses.find(
      (address: any) => address.type === 'SHIPPING' && address.is_primary
    );
    setShippingAddress([address1, address2, city, state, zip, country].filter(Boolean).join(', '));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEverythingSelected = useMemo(
    () => !!selectedDog?.label && selectedProducts.length > 0,
    [selectedDog?.label, selectedProducts.length]
  );

  const navigateBetweenSteps = () => {
    try {
      validateOneTimeOrderProducts(
        products.filter((product) =>
          selectedProducts.some((selectedCode) => selectedCode.label === product.code)
        )
      );
      setCurrentStep(isEverythingSelected ? 2 : 1);
    } catch {
      toast.error(MULTIPLE_MEAL_ONE_TIME_PRODUCTS, BOTTOM_TOAST);
    }
  };

  const context = {
    customer,
    dogs,
    fullProducts: products,
    selectedDog,
    setSelectedDog,
    selectedProducts,
    setSelectedProducts,
    isEverythingSelected,
    isLoading,
    navigateBetweenSteps,
    paymentMethod,
    onConfirm,
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 min-w-[700px] relative border">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        <h1 className="font-bold text-left mb-2">Create One-Time Order</h1>
        <LineInfo
          title="Customer"
          value={`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
        />
        <LineInfo title="Primary Shipping Address" value={shippingAddress} />
        <LineInfo title="Default Payment Method" value={paymentMethod} />
        <hr />
        <OneTimeOrderContext.Provider value={context}>
          {currentStep === 1 ? <OneTimeOrderStep1 /> : <OneTimeOrderStep2 />}
        </OneTimeOrderContext.Provider>
      </div>
    </div>
  );
};
