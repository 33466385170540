import * as React from 'react';

import { isAdmin, isOpsAdmin, isAllModuleAccessUser } from './constants';
import { Page } from './types';
import { ComponentBox } from '../component/ComponentBox';

export const Home = () => {
  const showUserManagementLink = isOpsAdmin() || isAdmin() || isAllModuleAccessUser();
  const showDiscountsLink = isOpsAdmin() || isAdmin() || isAllModuleAccessUser();
  const showItemsManagementLink = isOpsAdmin() || isAllModuleAccessUser();
  const showPromotionsLink = isAllModuleAccessUser();

  return (
    <div className="flex flex-col h-[calc(100vh-77px)] items-center justify-center bg-slate50 space-y-4">
      {showUserManagementLink && <ComponentBox title="Manage Users" page={Page.UserManagement} />}
      <ComponentBox title="Customer Lookup" page={Page.CustomerLookup} />
      {showDiscountsLink && <ComponentBox title="Manage Discounts" page={Page.DiscountsPage} />}
      {showItemsManagementLink && (
        <ComponentBox title="Manage Products & Items" page={Page.ManageDataPage} />
      )}
      {showPromotionsLink && <ComponentBox title="Manage Promotions" page={Page.PromotionsPage} />}
    </div>
  );
};
