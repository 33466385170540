import { toast } from 'react-toastify';

import { axios } from './api';
import { BOTTOM_TOAST, PET_PLAN_HOLD_STATUS_ERROR, generateErrorMsg } from './toastUtils';

interface UpdatePetStatusData {
  petId: string;
  petStatus: string;
}

export const createPetAndPetPlan = (petData: any, petPlan: any, startDate: any) => {
  const {
    dogName,
    weight,
    isNeuteredOrSpayed,
    selectedMonth,
    selectedYear,
    selectedBodyType,
    selectedLifestyle,
    customerId,
    selectedGender,
    selectedBreed,
    planSizeId,
    selectedHealthConditions,
  } = petData;
  axios
    .post(`pet_plans/create_pet_and_pet_plan`, {
      pet: {
        name: dogName,
        birth_year: selectedYear,
        breeds: selectedBreed,
        weight,
        gender: selectedGender,
        neutered: isNeuteredOrSpayed,
        birth_month: selectedMonth,
        recommended_calories_day: planSizeId,
        body_type: selectedBodyType,
        lifestyle: selectedLifestyle,
        health_conditions: selectedHealthConditions,
        account: customerId,
      },
      pet_plan: {
        product: petPlan?.subscription?.product?.code,
        frequency: petPlan?.subscription?.product?.frequency,
        unit_price: petPlan?.subscription?.price?.price,
        plan_size: planSizeId,
        quantity: 1,
        start_date: startDate,
      },
      account_id: customerId,
    })
    .then(() => {
      toast.success('Pet created successfully', BOTTOM_TOAST);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    })
    .catch((e) => {
      toast.error(generateErrorMsg(e), BOTTOM_TOAST);
    });
};

export const updatePet = (petData: any) => {
  const {
    petId,
    dogName,
    weight,
    isNeuteredOrSpayed,
    selectedMonth,
    selectedYear,
    selectedBodyType,
    selectedLifestyle,
    selectedHealthConditions,
    customerId,
    recommended_calories_day,
    breeds,
    gender,
  } = petData;
  axios
    .put(`pets/${petId}`, {
      name: dogName,
      birth_year: selectedYear,
      weight,
      neutered: isNeuteredOrSpayed,
      recommended_calories_day,
      birth_month: selectedMonth,
      body_type: selectedBodyType,
      lifestyle: selectedLifestyle,
      health_conditions: selectedHealthConditions,
      account: customerId,
      breeds,
      gender,
    })
    .then((response) => {
      window.location.reload();
    })
    .catch((e) => {
      toast.error(generateErrorMsg(e), BOTTOM_TOAST);
    });
};

export const updatePetAndPetPlan = (petData: any) => {
  const {
    petId,
    dogName,
    weight,
    isNeuteredOrSpayed,
    selectedMonth,
    selectedYear,
    selectedBodyType,
    selectedLifestyle,
    selectedHealthConditions,
    customerId,
    recommended_calories_day,
    productCode,
    price,
    frequency,
    petPlanId,
    mealPlanProductId,
    breeds,
    gender,
  } = petData;
  axios
    .put(`pets/${petId}`, {
      name: dogName,
      birth_year: selectedYear,
      weight,
      neutered: isNeuteredOrSpayed,
      recommended_calories_day,
      birth_month: selectedMonth,
      body_type: selectedBodyType,
      lifestyle: selectedLifestyle,
      health_conditions: selectedHealthConditions,
      account: customerId,
      breeds,
      gender,
    })
    .then(() => {
      axios
        .put(`pet_plans/${petPlanId}`, {
          account: customerId,
          unit_price: price,
          product: productCode,
          frequency,
          quantity: 1,
          plan_size: recommended_calories_day,
          pet_plan_id: petPlanId,
          pet_plan_product_id: mealPlanProductId,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          if (e.response?.status === 403 && e.response?.data === PET_PLAN_HOLD_STATUS_ERROR) {
            toast.error(
              'Payment Method Update Required. Please update your payment method. We are unable to make changes to your pup’s plan due to a payment issue with their most recent order. Please update your payment method before making changes to future orders.',
              BOTTOM_TOAST
            );
          } else {
            toast.error(generateErrorMsg(e), BOTTOM_TOAST);
          }
        });
    })
    .catch((e) => {
      toast.error(generateErrorMsg(e), BOTTOM_TOAST);
    });
};

export const cancelPetPlan = (petData: any) => {
  const { petId, petStatus, primaryReason, secondaryReason, additionalComments } = petData;
  const response = axios.post(`/pets/${petId}/cancel`, {
    is_bark: true,
    pet_status: petStatus,
    primary_cancellation_reason: primaryReason,
    secondary_cancellation_reason: secondaryReason,
    cancellation_reason_comments: additionalComments,
  });

  return response;
};

export const updatePetStatus = (updatePetStatusData: UpdatePetStatusData) => {
  const { petId, petStatus } = updatePetStatusData;
  const response = axios.put(`/pets/${petId}`, {
    status: petStatus,
  });
  return response;
};

export const createSurveyResponse = (surveyData: any) => {
  const { accountId, data } = surveyData;
  const response = axios.post(`accounts/${accountId}/add_survey_response/`, data);

  return response;
};
